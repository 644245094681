.child {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &__video {
    height: 157px;
    width: 230px;
    background-color: black;
    border-radius: 8px;
    object-fit: cover;
    overflow: hidden;
  }

  &__content {
    display: flex;
    flex: 1;

    &__details {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      line-height: 1.2;
    }
  }

  &:not(:last-child) {
    border-bottom: 0.5px solid rgba(51, 66, 80, 0.25);
  }

  &:last-child {
    border-bottom: 10px solid rgba(51, 66, 80, 0.15);
  }
}
