.container {
  flex: 1;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

input.input {
  color: white;
  background-color: rgba(255, 255, 255, 0.35);
  font-size: 20px;
  border-radius: 10px;
  min-height: 48px;
  padding-left: 20px;
  max-width: 335px;

  &::placeholder {
      color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    border-color: #007FFF;
  }
}

.logo {
  padding: 0 30px 20px 30px;
  min-width: 300px;
}

.form {
  position: relative;
  padding: 0 30px;
  width: 395px;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.submit {
  background-color: #007FFF;
  border: 0;
  border-radius: 10px;
  height: 48px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  position: absolute;
  bottom: -57px;

  &:hover {
    background-color: rgba(0, 127, 255, 0.9);
    border: 0;
  }

  &:focus {
    background-color: rgba(0, 127, 255, 0.85);
    border: 0;
  }

  &:active {
    background-color: rgba(0, 127, 255, 0.8);
    border: 0;
  }

  &:disabled {
    background-color: rgba(0, 127, 255, 0.8);
    border: 0;
  }

  &:disabled:hover {
    background-color: rgba(0, 127, 255, 0.8);
    border: 0;
  }
}

.error {
  position: absolute;
  top: -50px;
  background-color: 'rgba(255, 255, 255, 0.75)';
  color: red;
  padding: 0.25em 1em;
  border-radius: 8px;
}