.grid-container {
  display: grid;
  height: 100%;
  width: 100%;
  flex: 1;
  max-width: 400px;
  grid-gap: 20px;
  position: relative;
}

.video-wrapper-local {
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  width: auto;
  aspect-ratio: 183/283;
  transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out';
}

.video-wrapper-single {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 0 -10px -10px;
  transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out';
}

.video-wrapper-many {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out';
}

.local-video-local {
  grid-area: 1 / 1 / 3 / 3;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out, grid-area 0.5s ease-in-out';
}

.local-video-single {
  grid-area: 2 / 1 / 3 / 2;
  justify-content: center;
  align-items: center;
  transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out, grid-area 0.5s ease-in-out';
}

.local-video-many {
  grid-area: 2 / 1 / 3 / 2;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out, grid-area 0.5s ease-in-out';
}

.host-video {
  grid-area: 1 / 1 / 3 / 3;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out, grid-area 0.5s ease-in-out';
}

.host-video-many {
  grid-area: 1 / 1 / 2 / 3;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out, grid-area 0.5s ease-in-out';
}

.guest-video {
  grid-area: 2 / 2 / 3 / 3;
  position: relative;
  transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out, grid-area 0.5s ease-in-out';
}

.extra-guests {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.extra-guest {
  min-width: 112px;
  // max-width: 50%;
  flex: 1;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out';

  &:not(:first-child) {
    margin-left: 20px;
  }
}