.container {
  flex: 1;
  background: linear-gradient(to bottom, #F6F4F7, #D7DCE0);
}

.header {
  display: flex;
  flex: 1;
  justify-content: center;
  height: 130px;

  &__button {
    margin: 0;
    border-radius: 8px;
    border: 0;
    padding: 7px 20px;
    font-family: Roboto;
    background-color: rgba(3, 11, 34, 0.35);
    font-size: 22px;
    display: flex;
    height: auto;

    &:hover {
      background-color: rgba(3, 11, 34, 0.25);
    }

    &:active {
      background-color: rgba(3, 11, 34, 0.45);
    }

    &:focus {
      background-color: rgba(3, 11, 34, 0.5);
    }

    &:disabled {
      background-color: rgba(3, 11, 34, 0.1);
      opacity: 1;
    }

    &:disabled:hover {
      background-color: rgba(3, 11, 34, 0.1);
    }

    &:disabled:focus {
      background-color: rgba(3, 11, 34, 0.1);
    }
  }

  &__media {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-bottom: 10px;

    &__container {
      margin-left: 10px;
    }

    &__video {
      background-color: black;
      width: 84px;
      height: 50px;
      object-fit: cover;
      border-radius: 10px;
      border: 2px solid rgba(255, 255, 255, 0.6);
      overflow: hidden;
      cursor: pointer;
    }

    &__settings {
      position: absolute;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: 900;
    font-family: Roboto;
    color: white;
    text-align: center;
  }

  &__right {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;

    &__profile {
      height: 50px;
      width: 50px;
      border-radius: 25px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      margin-left: 20px;
    }
  }

  &__tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.content {
  display: flex;
  flex-direction: row;
  position: relative;

  &__divider {
    min-height: calc(100vh - 130px);
    background-color: rgba(51, 66, 80, 0.15);
    width: 10px;

    &__horizontal {
      width: 100%;
      height: 10px;
      background-color: rgba(51, 66, 80, 0.15);
    }
  }
}

/* Base class for the ScreeningDetails positioning */
.screening-details {
  position: absolute;
  right: -100%; /* Start off-screen */
  width: calc(50% - 5px); /* half the screen - half the divider width */
  transition: right 0.5s ease-in-out; /* Smooth transition for the right property */
  z-index: 100; /* Ensure it's above other content */
  backdrop-filter: blur(10px);
}

/* Class for when ScreeningDetails is visible */
.screening-details-visible {
  right: 0; /* Bring onto the screen */
}
