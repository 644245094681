.tab-content {
  flex: 1;
}

.tab-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style-type: none;
    margin: 0 0 0 0;
}

.tab-item-light {
    display: block;
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    text-decoration: none;
    height: 30px;
    padding: 0 20px;
    border: 2px solid transparent;
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto;
    margin: 0;
    position: relative;
    cursor: pointer;

    &:not(:first-child) {
        margin-left: 10px;
    }

    &__active {
        border: 2px solid rgba(255, 255, 255, 0.5);
        border-radius: 20px;
        font-weight: 900;
        color: white;
    }

    &__notification {
        position: absolute;
        right: -6px;
        top: 2px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 15px;
        width: 22px;
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.4;
        color: white;
    }
}

.tab-item-dark {
    display: block;
    color: rgba(51, 66, 80, 0.5);
    text-align: center;
    text-decoration: none;
    padding: 2px 20px;
    border: 2px solid transparent;
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto;
    margin: 0;
    position: relative;
    cursor: pointer;

    &:not(:first-child) {
        margin-left: 10px;
    }

    &__active {
        border: 2px solid rgba(51, 66, 80, 0.5);
        border-radius: 20px;
        font-weight: 900;
        color: black;
    }

    &__notification {
        position: absolute;
        right: -8px;
        top: 4px;
        background-color: rgba(51, 66, 80, 0.2);
        border-radius: 15px;
        width: 22px;
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.4;
        color: white;
    }
}