button.headerControl {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.25);
  border-width: 0px;
  padding: 10px;
  height: auto;
  display: flex;
  height: 50px;
  width: 84px;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.35);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.15);
  }
}

.buttonGroup {
  border: 2px solid rgba(51, 66, 80, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  margin: auto;

  // Targeting all children except the last one
  > *:not(:last-child) {
    position: relative;

    // Adding a divider after each child
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 2px; // Width of the divider
      background-color: rgba(51, 66, 80, 0.25); // Color of the divider
    }
  }
}

button.groupButton {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: transparent;
  border: 0;
  margin: 0;

  &:hover {
    background-color: rgba(51, 66, 80, 0.05);
  }

  &:active {
    background-color: rgba(51, 66, 80, 0.15);
  }
}