label.select-label {
  font-size: 15px;
  color: rgba(51, 66, 80, 1);
}

select.select {
  margin: 0;
}

label.select-label-dark {
  color: rgba(51, 66, 80, 1);
}

select.select-dark {
  height: 54px;
  border-radius: 27px;
  color: rgba(51, 66, 80, 1);
  border: 1px solid rgba(51, 66, 80, 1);

  &:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
  }
}

.select-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.select, .select:focus, .select:active {
  /* Add necessary styles to match your design */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  padding-right: 40px; /* Make room for the icon */
  padding-left: 50px;
  height: 54px;
  border-radius: 27px;
  color: rgba(51, 66, 80, 1);
  border: 1px solid rgba(51, 66, 80, 1);
}

.select-icon {
  position: absolute;
  width: 50px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Ensures the click on icon goes through to the select */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
}

.dropdown-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Ensures the click on icon goes through to the select */
}