.navigation {
    padding: 20px 20px 10px 20px;
    // border-bottom: variables.$border;
    // background: variables.$sg-blue;
    background-image: url("../assets/images/background.png");
    background-size: cover;
    background-position: 'top';
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__logo {
        height: 40px;
        object-fit: contain;
    }

    &__menu-icon {
        background: 0 0;
        border: none;
        text-align: right;
        cursor: pointer;

        &--open {
            .navigation__bar1 {
                -webkit-transform: rotate(-45deg) translate(-9px,6px);
                transform: rotate(-45deg) translate(-9px,6px);
            }

            .navigation__bar2 {
                opacity: 0;
            }

            .navigation__bar3 {
                -webkit-transform: rotate(45deg) translate(-9px,-6px);
                transform: rotate(45deg) translate(-9px,-6px);
            }
        }
    }

    &__bar {
        width: 35px;
        height: 5px;
        background-color: white;
        margin: 6px 0;
        transition: .7s;
    }
}