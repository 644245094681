.sidebar {
    width: 216px;
    height: 100%;
    background: rgba(51, 66, 80, 0.2);
    top: 0;
    left: 0;
    z-index: 1001;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &__links {
        margin: 0;
    }

    &__link {
        color: rgba(51, 66, 80, 1);
        display: block;
        cursor: pointer;
        margin: 0;
        padding: 8px 10px;

        a {
            color: rgba(51, 66, 80, 1);
            font-size: 17px;
            display: block;
            font-weight: 500;
            cursor: pointer;
            margin: 0;
            padding: 8px 20px;
            border-radius: 10px;
        }

        &__active {
            background-color: rgba(255, 255, 255, 0.75);
        }
    }
}