.container {
  flex: 1;
  padding: 10px 20px;

  &__header {
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-size: 17px;
      font-weight: 400;
      color: rgba(51, 66, 80, 1);
    }

    &__nav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      min-width: 100%;
    }
  }
}

.section {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    flex-direction: row;

    &__title {
      padding-left: 10px;
      font-size: 16px;
      font-weight: 900;
    }
  }

  &__content {
    padding: 20px 10px 10px 10px;
    margin: 0;

    &__text {
      font-size: 20px;
      color: #334250;
    }
  }
}

textarea.textarea {
  display: flex;
  flex: 1;
  border: 1px solid rgba(51, 66, 80, 0.2);
  border-radius: 10px;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  color: rgba(51, 66, 80, 1);
  font-family: Roboto;
  padding: 10px 20px;
  min-height: 90px;
  resize: none;
  box-sizing: border-box;
}

input {
  border: 1px solid rgba(51, 66, 80, 0.2);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 66, 80, 1);
  font-family: Roboto;
  padding: 7px 10px;
  line-height: 1.4;
  flex: 1;
  min-width: 150px;
}

select {
  border-radius: 5px;
  margin-left: 20px;
  margin-bottom: 0;
  flex: 1;
  min-width: 140px;
}

.location {
  margin-top: 10px;
  font-size: 17px;
  font-weight: 700;

  &__form {
    display: flex;
    flex-direction: row;
  }
}

.name {
  font-size: 17px;
  font-weight: 700;

  &__form {
    display: flex;
    flex-direction: row;
  }
}

.footer {
  margin-top: 30px;
  display: flex;
  flex-direction: row;

  &__submit {
    border-radius: 10px;
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    text-transform: none;
    border: 0;
    background-color: #007FFF;
    padding: 5px 20px;
    line-height: 1.4;
    min-width: 100px;

    &:disabled {
      background-color: rgba(51, 66, 80, 0.25);
      color: rgba(51, 66, 80, 1);
      opacity: 1;
    }
  }
}