button.mute {
  border-radius: 20px;
  background-color: rgba(51, 66, 80, 0.5);
  border-color: rgba(255, 255, 255, 0.35);
  border-width: 7px;
  padding: 24px;
  height: auto;
  display: flex;
  margin: 0;
  width: 205px;
  justify-content: center;

  &:hover {
    background-color: rgba(51, 66, 80, 0.35);
  }

  &:active {
    background-color: rgba(51, 66, 80, 0.6);
  }
}
