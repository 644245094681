.loading-button {
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.loading-button.is-loading {
  cursor: wait;
}

.loading-button.is-loading .spinner {
  width: 20px;
  height: 20px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top-color: #fff;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading-button:disabled {
  opacity: 0.7;
}