label.select-label {
  font-size: 15px;
  color: rgba(51, 66, 80, 1);
}

.select-loading {
  color: rgba(51, 66, 80, 0.5);
}

select.select {
  margin: 0;
}
