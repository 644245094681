.showImg {
  height: 108px;
  width: 185px;
  border-radius: 10px;
  object-fit: cover;
  margin: 2px 0;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:nth-child(3) {
    margin-right: 0;
  }
}

.container {
  flex: 1;
  background: linear-gradient(to bottom, #F6F4F7, #D7DCE0);

  &__header {
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.content {
  display: inline-block;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100%;
  padding: 10px 0;
  max-width: 576px;
  margin: auto;
}
