.img {
  border: 1px solid rgba(51, 66, 80, 0.25);
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  justify-content: center;
  display: flex;
  box-sizing: border-box;
}

.initials {
  font-weight: 400;
}

.contextMenu {
  position: absolute;
  top: 90px;
  right: 20px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(15px);
  width: 250px;
  z-index: 10;
}

.menuItem {
  padding: 7px 20px;
  color: black;
  font-size: 17px;

  &:not(:first-child) {
    border-top: 1px solid rgba(51, 66, 80, 0.18);
  }
}